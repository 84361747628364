<template lang="pug">
	.h-100.d-flex.flex-wrap.justify-content-center.align-items-sm-center
		.container.container-fluid(:class='{"container-fluid-payment" : isPaymentPage}')
			router-view
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {},
    data() {
        return {};
    },
    computed: {
        isPaymentPage() {
            return this.$route.name == 'payment';
        },
    },
    mounted: function () {
        document.body.classList.add('background');
    },
    beforeDestroy() {
        document.body.classList.remove('background');
    },
};
</script>

<style lang="scss" scoped>
.container-fluid-payment {
    align-self: self-start;
}
</style>
